import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import AxiosInstance from "../../api/AxiosInstance";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";

// modal view style
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 180,
  bgcolor: "#fff",
  boxShadow: "28px gray",
  p: 4,
  borderRadius: "30px",
  outline: "3px solid #2677B0",
  outlineOffset: "3px",
  border: "2px ridge gray",
};

const AdminLink = () => {
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const [openModal, setOpenModal] = useState(false);
  const handleLogout = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmLogout = async () => {
    let config = AxiosInstance.interceptors.request.use(res => {
      res.headers = {
        Authorization: `Bearer ${user?.TOKEN}`,
      };
      return res;
    });
    await AxiosInstance.post(`users/logoutUsers`, config)
      .then(data => {
        sessionStorage.removeItem("user");
        window.location.reload();
      })
      .catch(y => {
        //(y);
      });
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        {/* Enhanced modal for warning */}
        <Box
          sx={{
            position: "absolute",
            width: {
              xs: "50%",
              sm: "45%",
              md: "40%",
              lg: "20%",
              xl: "20%",
            },
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography
            id="logout-modal"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Warning: Do you want to logout?
          </Typography>
          <Button
            onClick={handleConfirmLogout}
            variant="contained"
            color="error"
            sx={{ mr: 2 }}
          >
            Yes, Logout
          </Button>
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            variant="outlined"
            type="button"
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <ul>
        <li>
          <NavLink
            activeclassname="true"
            to="/admindashboard"
            title="Home Page"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            activeclassname="true"
            to="/admin/fetaureuploads"
            title="Features Page"
          >
            Features
          </NavLink>
        </li>
        <li>
          <NavLink
            activeclassname="true"
            to="admin/uploadvideo"
            title="Upload Video Page"
          >
            Upload Video
          </NavLink>
        </li>

        <li>
          <IconButton color="inherit" onClick={() => handleLogout()}>
            <LogoutIcon />{" "}
          </IconButton>

          {/* <LogoutButton /> */}
        </li>
      </ul>
    </>
  );
};

export default AdminLink;
