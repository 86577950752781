import React, { useState } from "react";
import { TextField, Button, FormControl, Box, Grid } from "@mui/material";
import styles from "./_videoPost.module.css";
import Styles from "./_adminlogin.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
const EditSubFea3 = ({ editSubFea3, handleClose }) => {
  let navigate = useNavigate();
  const [RoleData, setRoleData] = useState([]);
  const [appData, setAppData] = useState([]);
  const [featureData, setFeatureData] = useState([]);

  let [loading, setLoading] = useState(false);
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;
  // //(editSubFea3);
  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  //(appData);
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#015787",
    color: "#fff",
    fontWeight: "bold",
  };
  const gridContainer = {
    margin: "0 auto",
    // padding: "1em 0",
    gap: 20,
  };

  let [feature, setFeature] = useState([editSubFea3.feaname]);
  let [sub_feature, setSub_Feature] = useState([editSubFea3.level1name]);
  let [sub_feature2, setSub_Feature2] = useState([editSubFea3.parentName]);
  let [sub_feature3, setSub_Feature3] = useState(editSubFea3.name);

  //   let [level2, , setLevel2] = useState([editSubFea2.name]);

  let [role, setRole] = useState([editSubFea3.role]);
  let [application, setApplication] = useState([editSubFea3.app]);
  let [weight, setWeight] = useState(editSubFea3.weight);

  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata =
            user.role[0] === "Admin"
              ? data?.data.getApp
              : data?.data?.getApplication;
          //("roledata", roledata);
          if (typeof roledata === "string") {
            setAppData([roledata]);
          } else {
            setAppData(roledata);
          }
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpenApp = () => {
    fetchAppData();
  };
  // fetching Role data get api
  const fetchRoleData = async () => {
    let appName = application === "" ? "" : `?application=${application}`;
    // here seperate api call for admin and superadmin because in admin in params donot pass application
    try {
      await AxiosInstance.get(`/users/getRole`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          setRoleData(roledata);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // fetch feature data
  const fetchFeatureData = async () => {
    try {
      await AxiosInstance.get(
        `/users/getFeature1/${role}/${application}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let featureData =
            data?.data?.features.length > 0
              ? data?.data?.features[0].features
              : [];
          //   setAppData(roledata);
          let data1 = featureData.map(x => {
            return x.name;
          });
          setFeatureData(data1);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    //(e);sub_feature3
    if (application === "") {
      toast.error("Please select Application");
    } else if (role === "") {
      toast.error("Please select Role");
    } else if (feature === null) {
      toast.error("Please select Feature");
    } else if (sub_feature3.trim() === "") {
      toast.error("Please Enter Sub_feature3");
    } else if (weight === "") {
      toast.error("Please Enter weight");
    } else {
      let config = {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
          //   "Content-Type": "multipart/form-data",
        },
      };

      let formData = {
        role: role[0],
        application: application[0],
        find: "subFeatures2",
        featureId: editSubFea3.feaId,
        subfeatureId: editSubFea3.level1Id,
        subfeature1Id: editSubFea3.parentId,
        id: editSubFea3._id,
        name: sub_feature3,
        weight: weight,
      };

      await AxiosInstance.patch(`/users/editFeature`, formData, config)
        .then(res => {
          toast.success("Sub feature level 3 added successfully");
          navigate("/admindashboard");
          handleClose();
        })
        .catch(error => {
          toast.error("Error submitting form");
          setLoading(false);
        });
    }
    setLoading(false);
  };
  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 3) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  return (
    <section style={{ padding: "0 5vw"}}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Grid
          align="center"
          item
          sx={{
            width: "100%",
            borderBottom: "1px solid #015787",
          }}
          style={paperStyle}
        >
          <span
            style={{ color: "#015787", fontSize: "20px", fontWeight: "bold" }}
          >
            {" "}
            Edit Subfeature Level3{" "}
          </span>
        </Grid>
        <form className={styles.formBlock} onSubmit={handleSubmit}>
          <Grid item container sm={12} spacing={2}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  disabled
                  freeSolo
                  id="application"
                  label="Application *"
                  options={appData}
                  className={styles.formField}
                  value={application}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  // disablePortal
                  freeSolo
                  id="role"
                  disabled
                  label="Role *"
                  options={RoleData}
                  className={styles.formField}
                  value={role}
                />
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid item sm={12} xs={12} md={12}>
                {" "}
                <TextField
                  // disablePortal
                  freeSolo
                  disabled
                  id="feature"
                  label="Feature *"
                  className={styles.formField}
                  value={feature}
                />
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  // disablePortal
                  freeSolo
                  disabled
                  id="sub_feature"
                  label="Sub Feature *"
                  className={styles.formField}
                  value={sub_feature}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled
                  id="sub_feature2"
                  name="sub_feature2"
                  label="Level 2 Title *"
                  type="text"
                  value={sub_feature2}
                  className={styles.formField}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  fullWidth
                  id="sub_feature3"
                  name="sub_feature3"
                  label=" Level 3 Title *"
                  type="text"
                  value={sub_feature3}
                  onChange={e => {
                    setSub_Feature3(e.target.value);
                  }}
                  className={styles.formField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  fullWidth
                  id="weight"
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  name="weight"
                  label="Weight *"
                  type="tel"
                  value={weight}
                  onChange={event => {
                    setWeight(event.target.value);
                  }}
                  className={styles.formField}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Grid>

          {loading ? (
            <Button className={Styles.LoginButtonLoader}>
              {" "}
              <Loader />
            </Button>
          ) : (
            <Grid
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                flexWrap: "wrap",

                padding: "1vw",
              }}
            >
              <Button
                type="button"
                variant="contained"
                size="medium"
                color="error"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button type="submit" variant="contained" size="medium">
                Submit
              </Button>
            </Grid>
          )}
        </form>
        {/* </Paper> */}
      </Grid>
    </section>
  );
};
export default EditSubFea3;
