import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Box,
  Autocomplete,
  Grid,
  Paper,
} from "@mui/material";
import styles from "./_videoPost.module.css";
import Styles from "./_adminlogin.module.css";
import AxiosInstance from "../../api/AxiosInstance";

import toast from "react-hot-toast";
import Loader from "../loader/Loader";

const AddFeatures = () => {
  const [RoleData, setRoleData] = useState([]);
  const [appData, setAppData] = useState([]);
  let [loading, setLoading] = useState(false);

  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };

  const gridContainer = {
    margin: "0 auto",
    padding: "1em 0",
    gap: 20,
  };

  let [feature, setFeature] = useState("");
  let [role, setRole] = useState(null);

  const [initialValues, setInitialValues] = useState({
    application: "",
    weight: "",
  });

  const { application, weight } = initialValues;

  const handleChange = (name, value) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  // fetching Role data get api
  const fetchRoleData = async () => {
    let appName = application === "" ? "" : `?application=${application}`;
    try {
      await AxiosInstance.get(`/users/getRole${appName}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          setRoleData(roledata);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpen = () => {
    fetchRoleData();
  };

  // fetching Application data get api
  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata =
            user.role[0] === "Admin"
              ? data?.data.getApp
              : data?.data?.getApplication;
          if (typeof roledata === "string") {
            setAppData([roledata]);
          } else {
            setAppData(roledata);
          }
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 3) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleMenuOpenApp = () => {
    fetchAppData();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    if (application === "" || application === null) {
      toast.error("Please select Application");
    } else if (role === "" || role === null) {
      toast.error("Please select Role");
    } else if (feature.trim() === "") {
      toast.error("Please enter Feature");
    } else if (weight === "") {
      toast.error("Please Enter weight");
    } else {
      let config = {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      };
      let formData = {
        role: role,
        Application: application,
        features: [
          {
            name: feature.trim(),
            weight: weight,
          },
        ],
      };
      await AxiosInstance.post(`/users/addFeature`, formData, config)
        .then(res => {
          toast.success("feature added successfully");

          setInitialValues({
            application: "",

            weight: "",
            sub_feature: "",
            description: "",
          });
          setRole(null);
          setFeature("");
        })
        .catch(error => {
          let err = error?.response?.data?.error;
          toast.error(err);
          setLoading(false);
        });
    }
    setLoading(false);
  };

  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <h2 style={{ color: "#015787" }}> Add Feature </h2>
            <br />
          </Grid>
          <form className={styles.form} onSubmit={handleSubmit}>
            <Box className={styles.BoxStyle}>
              <FormControl fullWidth className={styles.selectField}>
                <Autocomplete
                  id="application"
                  options={appData}
                  onOpen={handleMenuOpenApp}
                  value={application}
                  onChange={(event, newValue) => {
                    handleChange("application", newValue);
                    setRole(null);
                    // setFeature("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Application *" />
                  )}
                />
              </FormControl>
              <FormControl fullWidth className={styles.selectField}>
                {" "}
                <Autocomplete
                  // disablePortal

                  id="role"
                  disabled={application === "" || application === null}
                  options={RoleData}
                  onOpen={handleMenuOpen}
                  value={role}
                  onChange={(event, newValue) => {
                    setRole(newValue);
                    // setFeature("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Role *" />
                  )}
                />
              </FormControl>
            </Box>

            <Box className={styles.BoxStyle}>
              <TextField
                fullWidth
                id="feature"
                name="feature"
                label="Feature Title *"
                type="text"
                value={feature}
                onChange={event => {
                  setFeature(event.target.value);
                }}
                className={styles.formField}
                autoComplete="off"
              />

              <TextField
                fullWidth
                id="weight"
                name="weight"
                onPaste={preventPasteNegative}
                onKeyPress={preventMinus}
                label="Weight *"
                type="tel"
                value={weight}
                onChange={event => {
                  handleChange("weight", event.target.value);
                }}
                className={styles.formField}
                autoComplete="off"
              />
            </Box>

            {loading ? (
              <Button className={Styles.LoginButtonLoader}>
                {" "}
                <Loader />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                className={Styles.LoginButton}
              >
                Submit
              </Button>
            )}
          </form>
        </Paper>
      </Grid>
    </section>
  );
};

export default AddFeatures;
