import axios from "axios";

export default axios.create({
  baseURL: "https://testdemovideob.qspiders.com",
  // baseURL: "https://wikib.qspiders.com",

  headers: {
    "Content-Type": "application/json",
  },
});

export let fileAxios = axios.create({
  baseURL: "https://testdemovideob.qspiders.com",
  // baseURL: "https://wikib.qspiders.com",

  headers: {
    "Content-Type": "multipart/form-data",
  },
});
export let VideoDemo = axios.create({
  baseURL: "https://testdemovideob.qspiders.com/",
  // baseURL: "https://wikib.qspiders.com/",

  headers: {
    "Content-Type": "application/json",
  },
});
