import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Box,
  Autocomplete,
  Grid,
} from "@mui/material";
import styles from "./_videoPost.module.css";
import Styles from "./_adminlogin.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";

const EditFeature = ({ editFea, handleClose }) => {
  let navigate = useNavigate();
  const [RoleData, setRoleData] = useState([]);
  const [appData, setAppData] = useState([]);
  let [loading, setLoading] = useState(false);
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const gridContainer = {
    margin: "0 auto",
    // padding: "1em 0",
    gap: 20,
  };
  const paperStyle = {
    // padding: 20,
    paddingBottom: 20,
    gap: 0,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexwrap: "wrap",
    // margin: "12vh auto",
  };
  let [feature, setFeature] = useState(editFea.name);
  let [role, setRole] = useState([editFea.role]);
  let [application, setApplication] = useState([editFea.app]);
  let [weight, setWeight] = useState(editFea.weight);

  // fetching Application data get api
  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata =
            user.role[0] === "Admin"
              ? data?.data.getApp
              : data?.data?.getApplication;
          if (typeof roledata === "string") {
            setAppData([roledata]);
          } else {
            setAppData(roledata);
          }
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpenApp = () => {
    fetchAppData();
  };
  // fetching Role data get api
  const fetchRoleData = async () => {
    let appName = application === "" ? "" : `?application=${application}`;
    // here seperate api call for admin and superadmin because in admin in params donot pass application
    try {
      await AxiosInstance.get(`/users/getRole`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          setRoleData(roledata);
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpen = () => {
    fetchRoleData();
  };
  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 3) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleSubmit = async e => {
    //(feature);
    e.preventDefault();
    setLoading(true);
    //(e);
    if (application === "") {
      toast.error("Please select Application");
    } else if (role === "") {
      toast.error("Please select Role");
    } else if (feature.trim() === "") {
      toast.error("Please Enter Feature");
    } else if (weight === "") {
      toast.error("Please Enter weight");
    } else {
      let config = {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
          //   "Content-Type": "multipart/form-data",
        },
      };
      let formData = {
        role: role[0],
        application: application[0],
        find: "features",
        id: editFea._id,
        name: feature,
        weight: weight,
        // discription: description,
      };
      await AxiosInstance.patch(`/users/editFeature`, formData, config)
        .then(res => {
          toast.success(" feature added successfully");
          navigate("/admindashboard");
          handleClose();
        })
        .catch(error => {
          toast.error("Error submitting form");
          setLoading(false);
        });
    }
    setLoading(false);
  };

  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Grid
          align="center"
          item
          sx={{
            width: "100%",
            borderBottom: "1px solid #015787",
          }}
          style={paperStyle}
        >
          <span
            style={{ color: "#015787", fontSize: "20px", fontWeight: "bold" }}
          >
            {" "}
            Edit Feature{" "}
          </span>
        </Grid>
        <form className={styles.formBlock} onSubmit={handleSubmit}>
          <Grid item container sm={12} spacing={2}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={6} xs={12} md={6}>
                <Autocomplete
                  disabled
                  id="application"
                  options={appData}
                  onOpen={handleMenuOpenApp}
                  value={application}
                  onChange={(event, newValue) => {
                    setApplication(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Application *" />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <Autocomplete
                  disabled
                  id="role"
                  options={RoleData}
                  onOpen={handleMenuOpen}
                  value={role}
                  onChange={(event, newValue) => {
                    // handleChange("role", newValue);
                    if (application === null) {
                      role = "";
                    }
                    setRole(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Role *" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={6} xs={12} md={6}>
                {" "}
                <TextField
                  fullWidth
                  id="feature"
                  name="feature"
                  label="Feature Title *"
                  type="text"
                  value={feature}
                  onChange={event => {
                    setFeature(event.target.value);
                  }}
                  className={styles.formField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <TextField
                  fullWidth
                  id="weight"
                  name="weight"
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                  label="Weight *"
                  type="tel"
                  value={weight}
                  onChange={event => {
                    setWeight(event.target.value);
                  }}
                  className={styles.formField}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            {/* <Box className={styles.BoxStyle}> */}

            {/* </Box> */}

            {loading ? (
              <Button className={Styles.LoginButtonLoader}>
                {" "}
                <Loader />
              </Button>
            ) : (
              <Grid
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                  padding: "1vw",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  size="medium"
                  // fullWidth

                  color="error"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  // fullWidth
                  // className={Styles.LoginButton}
                >
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </section>
  );
};

export default EditFeature;
