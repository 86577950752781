import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Box,
  Autocomplete,
  Grid,
} from "@mui/material";
import styles from "./_videoPost.module.css";
import Styles from "./_adminlogin.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
const EditSubFea2 = ({ editSubFea2, handleClose }) => {
  let navigate = useNavigate();
  const [RoleData, setRoleData] = useState([]);
  const [appData, setAppData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [subFeatureData, setSubFeatureData] = useState([]);

  let [loading, setLoading] = useState(false);
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const gridContainer = {
    margin: "0 auto",
    // padding: "1em 0",
    gap: 20,
  };
  const paperStyle = {
    // padding: 20,
    paddingBottom: 20,
    gap: 0,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexwrap: "wrap",
    // margin: "12vh auto",
  };

  let [feature, setFeature] = useState([editSubFea2.feaname]);
  let [sub_feature, setSub_Feature] = useState([editSubFea2.parentName]);
  let [sub_feature2, setSub_Feature2] = useState(editSubFea2.name);

  //   let [level2, , setLevel2] = useState([editSubFea2.name]);

  let [role, setRole] = useState([editSubFea2.role]);
  let [application, setApplication] = useState([editSubFea2.app]);
  let [weight, setWeight] = useState(editSubFea2.weight);

  // fetching Application data get api
  //([editSubFea2.feaname]);
  //([editSubFea2.parentName]);
  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata =
            user.role[0] === "Admin"
              ? data?.data.getApp
              : data?.data?.getApplication;
          //("roledata", roledata);
          if (typeof roledata === "string") {
            setAppData([roledata]);
          } else {
            setAppData(roledata);
          }
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpenApp = () => {
    fetchAppData();
  };
  // fetching Role data get api
  const fetchRoleData = async () => {
    let appName = application === "" ? "" : `?application=${application}`;
    // here seperate api call for admin and superadmin because in admin in params donot pass application
    try {
      await AxiosInstance.get(`/users/getRole`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          setRoleData(roledata);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpen = () => {
    fetchRoleData();
  };
  // fetch feature data
  const fetchFeatureData = async () => {
    try {
      await AxiosInstance.get(
        `/users/getFeature1/${role}/${application}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let featureData =
            data?.data?.features.length > 0
              ? data?.data?.features[0].features
              : [];
          //   setAppData(roledata);
          let data1 = featureData.map(x => {
            return x.name;
          });
          setFeatureData(data1);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleMenuOpenFeature = () => {
    fetchFeatureData();
  };

  // fetch sub feature level 1 data
  const fetchSubFeature1Data = async () => {
    let fId = editSubFea2.parentId ? editSubFea2.parentId : "";
    // //(sub_feature);
    let subfea = editSubFea2.parentId ? sub_feature._id : "";
    try {
      await AxiosInstance.get(
        `/users/getSubfeature1/${fId}/${role}/${application}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let SubFeatureData =
            data?.data && data?.data?.subfeatures.length > 0
              ? data?.data?.subfeatures
              : [];
          let data1 = SubFeatureData.map(x => {
            return x.name;
          });
          setSubFeatureData(data1);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpenSubFeature = () => {
    fetchSubFeature1Data();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    //(e);sub_feature2
    if (application === "") {
      toast.error("Please select Application");
    } else if (role === "") {
      toast.error("Please select Role");
    } else if (feature === null) {
      toast.error("Please select Feature");
    } else if (sub_feature2.trim() === "") {
      toast.error("Please Enter sub_feature2");
    } else if (weight === "") {
      toast.error("Please Enter weight");
    } else {
      let config = {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
          //   "Content-Type": "multipart/form-data",
        },
      };
      let formData = {
        role: role[0],
        application: application[0],
        find: "subFeatures1",
        featureId: editSubFea2.feaId,
        subfeatureId: editSubFea2.parentId,
        id: editSubFea2._id,
        name: sub_feature2,
        weight: weight,
      };

      await AxiosInstance.patch(`/users/editFeature`, formData, config)
        .then(res => {
          toast.success("Sub feature level 2 added successfully");
          navigate("/admindashboard");
          handleClose();
        })
        .catch(error => {
          toast.error("Error submitting form");
          setLoading(false);
        });
    }
    setLoading(false);
  };
  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 3) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Grid
          align="center"
          item
          sx={{
            width: "100%",
            borderBottom: "1px solid #015787",
          }}
          style={paperStyle}
        >
          <span
            style={{ color: "#015787", fontSize: "20px", fontWeight: "bold" }}
          >
            {" "}
            Edit Subfeature Level2{" "}
          </span>


        </Grid>
        <form className={styles.formBlock} onSubmit={handleSubmit}>
          <Grid item container sm={12} spacing={2}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={6} xs={12} md={6}>
                {" "}
                <Autocomplete
                  disabled
                  freeSolo
                  id="application"
                  options={appData}
                  onOpen={handleMenuOpenApp}
                  value={application}
                  onChange={(event, newValue) => {
                    // handleChange("application", newValue);
                    setApplication(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Application *" />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <Autocomplete
                  disabled
                  freeSolo
                  id="role"
                  options={RoleData}
                  onOpen={handleMenuOpen}
                  value={role}
                  onChange={(event, newValue) => {
                    // handleChange("role", newValue);
                    if (application === null) {
                      role = "";
                    }
                    setRole(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Role *" />
                  )}
                />
              </Grid>
              <Grid item container sm={12} spacing={2}>
                <Grid item sm={6} xs={12} md={6}>
                  <TextField
                    // disablePortal
                    freeSolo
                    disabled
                    id="feature"
                    label="Feature *"
                    className={styles.formField}
                    value={feature}
                  />
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <TextField
                    // disablePortal
                    freeSolo
                    disabled
                    id="sub_feature"
                    label="Sub Feature *"
                    className={styles.formField}
                    value={sub_feature}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={12} spacing={2}>
                <Grid item sm={6} xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="sub_feature2"
                    name="sub_feature2"
                    label="Sub Feature Level 2 Title *"
                    type="text"
                    value={sub_feature2}
                    onChange={e => {
                      setSub_Feature2(e.target.value);
                    }}
                    className={styles.formField}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="weight"
                    name="weight"
                    onPaste={preventPasteNegative}
                    onKeyPress={preventMinus}
                    label="Weight *"
                    type="tel"
                    value={weight}
                    onChange={event => {
                      setWeight(event.target.value);
                    }}
                    className={styles.formField}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {loading ? (
            <Button className={Styles.LoginButtonLoader}>
              {" "}
              <Loader />
            </Button>
          ) : (
            <Grid
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                flexWrap: "wrap",
                padding: "1vw",
              }}
            >
              <Button
                type="button"
                variant="contained"
                size="medium"
                color="error"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                // fullWidth
                // className={Styles.LoginButton}
              >
                Submit
              </Button>
            </Grid>
          )}
        </form>
      </Grid>
    </section>
  );
};

export default EditSubFea2;
